@import '../../../../scss/theme-bootstrap';
///
/// basic formatter
///
.basic-formatter {
  &__header {
    text-align: center;
    margin: 0 0 20px 0;
    padding: 0 20px;
    @include breakpoint($landscape-up) {
      margin-bottom: 25px;
    }
  }
  &__headline {
    @include h1;
    margin: 0 0 7px 0;
    @include breakpoint($landscape-up) {
      margin-bottom: 15px;
    }
    > p {
      margin: 0;
      line-height: inherit;
    }
  }

  /// mobile homepage
  // class added in cms
  &.homepage-wrapper--mobile {
    .basic-formatter__item {
      padding-#{$ldirection}: 16px;
      padding-#{$rdirection}: 16px;
      &:nth-child(1) {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
      }
    }
  }
  
  /// desktop homepage
  // class added in cms
  &.homepage-wrapper--desktop {
    .basic-formatter__item.content-pad--divider:nth-child(1) {
      padding-bottom: 0;
      border-bottom: 0;
    }
    .basic-formatter__item.content-pad--divider:nth-child(2) {
      padding-bottom: 25px;
    }
  }

  // Class added in cms - /lighting-story
  &.story-lighting-page {
    .basic-formatter__item.content-pad--divider:nth-child(1) {
      padding-bottom: 0;
      @include breakpoint($landscape-up) {
        border-bottom: 0;
      }
    }
    .basic-carousel-formatter {
      margin-top: 36px;
    }
    .pro-artists ul.default-ul li {
      @include breakpoint($landscape-down) {
        margin-#{$ldirection}: 1em;
      }
    }
  }

  // Class added in cms - /primer-story
  &.story-primer-page {
    .story-primer-carousel {
      background: $primer-story-bg-color;
    }
    > .basic-formatter__items {
      > .basic-formatter__item:nth-child(1) {
        @include breakpoint($landscape-down) {
          border-bottom: $border;
          padding-bottom: 10px;
          margin-bottom: 20px;
        }
      }
      > .basic-formatter__item:nth-child(2) {
        @include breakpoint($landscape-down) {
          margin-bottom: 0;
        }
      }
    }
    .primer-story-mobile-touts {
      background: $primer-story-bg-color;
      padding: 30px 0;
      h2 {
        font-size: 65px;
        line-height: 0.75;
        margin: 0 0 10px 0;
        font-family: $font--heading-display;
      }
      > .basic-formatter__item {
        margin-bottom: 25px;
      }
    }
  }

  // Class added in cms - /studio-skin-story 
  &.story-studio-skin-page {
    .basic-formatter__items:nth-child(1) {
      > .basic-formatter__item {
        @include breakpoint($landscape-down) {
          &:nth-child(1) {
            border-bottom: $border;
            padding-bottom: $spacing--mobile;
            margin-bottom: $spacing--mobile;
          }
          &:nth-child(3),
          &:nth-child(4) {
            margin-bottom: $spacing--mobile;
          }
          &:nth-child(3) {
            .hero-large {
              margin-bottom: 10px;
            }
          }
        }
      }
      .basic-formatter__item:nth-child(3) {
        .basic-carousel-formatter {
          margin-bottom: 26px;
        }
      }
    }
  }
}
